import { computed, ComputedRef } from 'vue'
import { AsfSizes, sizes } from '#tailwindConfig'

type Sizes = {
  sizeProps: ComputedRef<{ height: string; width: string }>
}

const round = (num: number) => {
  const factorOfTen = Math.pow(10, 4)
  return Math.round(num * factorOfTen) / factorOfTen
}

const getSize = (width: string, height: string) => {
  let _height = height
  let _width = width
  if (height.includes('rem')) {
    _height = `${round(parseFloat(height.slice(0, -3)) * 16)}px`
  }

  if (width.includes('rem')) {
    _width = `${round(parseFloat(width.slice(0, -3)) * 16)}px`
  }

  return { height: _height, width: _width }
}

export function useSize<T extends { size?: AsfSizes }>(props: T, defaultValue: AsfSizes = '6'): Sizes {
  const sizeProps = computed(() => {
    if (!props.size) {
      if (Array.isArray(defaultValue)) {
        const [width = '6', height = '6'] = defaultValue
        return getSize(sizes[width], sizes[height])
      } else {
        return getSize(sizes[defaultValue], sizes[defaultValue])
      }
    }

    if (Array.isArray(props.size)) {
      const [width = '6', height = '6'] = props.size
      return getSize(sizes[width], sizes[height])
    } else {
      return getSize(sizes[props.size], sizes[props.size])
    }
  })

  return { sizeProps }
}
